(function ($) {
  $(window).load(function () {
    setTimeout(() => {
      if ($(window).width() < 768) {
        return;
      }

      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $stickyHeader = $(".header.sticky");
      var $message = $(".form_success_message");

      if (!$stickyHeader.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $stickyHeader.outerHeight() - 25;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $stickyHeader.outerHeight() - 25,
      });
    }, 500);

    $(".owl-carousel:not(.slider)").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],

      dots: false,

      // drag options
      mouseDrag: false,
    });

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>",
      ],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });
  });

  $(document).ready(function () {
    var windowWidth = $(window).width();
    if (windowWidth > 1200) {
      $(window).scroll(function () {
        if ($(window).scrollTop() > 0) {
          $("body").addClass("sticky");
          $(".header").addClass("sticky");
        } else {
          $("body").removeClass("sticky");
          $(".header").removeClass("sticky");
        }
      });
    }

    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });
  });
})(jQuery);

// vacancy sections fix

let leadSection = document.querySelector(".vacancy_category .lead-section");
let contentSection = document.querySelector(
  ".vacancy_category section.content"
);
let allSections = document.querySelectorAll(".vacancy_category section");

let contentIndex = Array.from(allSections).indexOf(contentSection);

if (leadSection && contentSection) {
  if (leadSection.parentElement) {
    leadSection.parentElement.removeChild(leadSection);
  }

  allSections[contentIndex].insertAdjacentElement("beforebegin", leadSection);
}

// // vacancy detail title
let title = document.querySelector(".vacancy_vacancy_detail .info h1");
let introSection = document.querySelector(
  ".vacancy_vacancy_detail .intro-section .container .container-holder"
);

if (title && introSection) {
  title.classList.add("vacancy-title");
  introSection.appendChild(title);
}

// hide read more button vacancy

let readMoreButton = document.querySelector(".vacancy_category .lead-section .info .btn");
let extraDescriptionDiv = document.querySelector(".vacancy_category .js-description-extra");

if (readMoreButton && extraDescriptionDiv) {
  let extraDescriptionColumns = extraDescriptionDiv.querySelectorAll(".column");
  extraDescriptionColumns.forEach((col) => {
    if (col.innerHTML.length > 0) {
      return;
    }
    readMoreButton.style.display = "none";
    extraDescriptionDiv.style.display = "none";
  });
}
